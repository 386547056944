<div class="mc-control-switcher">
	<button class="mc-control-switcher-button"
			[ngClass]="{'active': selectedControlType === controlTypes.move}"
			(click)="onSwitchControlMode($event, controlTypes.move)"
			#popoverMove="ngbPopover"
			(document:click)="popoverMove.close()"
			triggers="mouseenter:mouseleave"
			placement="right"
			popoverClass="mc-popover mc-popover-image-visualisation"
			[ngbPopover]="'control_switcher.move' | translate"></button>
	<button class="mc-control-switcher-button" [ngClass]="{'active': selectedControlType === controlTypes.rotate}"
			(click)="onSwitchControlMode($event, controlTypes.rotate)"
			#popoverRotate="ngbPopover"
			(document:click)="popoverRotate.close()"
			triggers="mouseenter:mouseleave"
			placement="right"
			popoverClass="mc-popover mc-popover-image-visualisation"
			[ngbPopover]="'control_switcher.rotate' | translate"></button>
</div>
