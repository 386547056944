import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, } from '@angular/core';

@Component({
	selector: 'app-scrollbar',
	templateUrl: './scrollbar.template.html',
	styleUrls: ['./scrollbar.style.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollbarComponent {
	constructor(
		@Inject(ElementRef) readonly elementRef: ElementRef<HTMLElement>,
	) {
	}
	
	get verticalScrolled(): number {
		const {
			scrollTop,
			scrollHeight,
			clientHeight
		} = this.elementRef.nativeElement;
		
		return scrollTop / (scrollHeight - clientHeight);
	}
	
	get verticalPosition(): number {
		return this.verticalScrolled * (100 - this.verticalSize);
	}
	
	get verticalSize(): number {
		const { clientHeight, scrollHeight } = this.elementRef.nativeElement;
		
		return Math.ceil((clientHeight / scrollHeight) * 100);
	}
	
	get hasVerticalBar(): boolean {
		return this.verticalSize < 100;
	}
	
	@HostListener('scroll')
	onScroll(): void {
		// trigger change detection, just in case has been added
	}
	
	onVertical(scrollTop: number): void {
		this.elementRef.nativeElement.scrollTop = scrollTop;
	}
}
