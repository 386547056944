import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SELECTORS } from '@root/app.config';

declare var $: any;

@Component({
	selector: 'app-delete-modal',
	templateUrl: './delete-modal.component.html',
	styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit, OnDestroy {
	
	constructor(
		private dialogRef: MatDialogRef<DeleteModalComponent>
	) {
	}
	
	ngOnInit() {
		$(SELECTORS.overlay_container).addClass('internal');
	}
	
	ngOnDestroy() {
		$(SELECTORS.overlay_container).removeClass('internal');
	}
	
	close(): void {
		this.dialogRef.close();
	}
	
	delete(): void {
		this.dialogRef.close(true);
	}
}
