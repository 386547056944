import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { ResizeDirective } from '@core/directives/domElems/resize.directive';

import { BrowserModule } from '@angular/platform-browser';
import { MainPageComponent } from '@features/main-page/main-page.component';
import { ModelViewerComponent } from '@features/model-viewer/model-viewer.component';
import { ToolBarComponent } from '@features/tool-bar/tool-bar.component';
import { ColorChangeComponent } from '@features/color-change/color-change.component';
import { SizeChangeComponent } from '@features/size-change/size-change.component';

import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { ColorPickerComponent } from '@shared/color-picker/color-picker.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { AddBlindComponent } from '@features/add-blind/add-blind.component';
import { BlindListComponent } from '@features/blind-list/blind-list.component';
import { ScrollbarModule } from '@core/plugins/scrollbar/scrollbar.module';
import { HttpClientModule } from '@angular/common/http';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TranslateModule } from '@ngx-translate/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FrameChangeComponent } from '@features/frame-change/frame-change.component';
import { OperationChangeComponent } from '@features/operation-change/operation-change.component';
import { PipesModule } from '@core/pipes/pipes.module';
import { MountingChangeComponent } from '@features/mounting-change/mounting-change.component';
import { OverviewPageComponent } from '@features/overview-page/overview-page.component';
import { SummaryTableComponent } from '@features/summary-table/summary-table.component';
import { RetailarActionComponent } from '@features/retailar-action/retailar-action.component';
import { CountryModalComponent } from '@shared/modals/country-modal/country-modal.component';
import { BlindTypeModalComponent } from '@shared/modals/blind-type-modal/blind-type-modal.component';
import { BlindItemDescriptionComponent } from '@features/blind-list/blind-item-description/blind-item-description.component';
import { ShutterControlComponent } from '@features/shutter-control/shutter-control.component';
import { LatinValidatorDirective } from '@core/directives/validate/validate-latin.directive';
import { InputEmailValidator } from '@core/directives/validate/validate-email.directive';
import { OutdoorListComponent } from './blind-list/outdoor-list/outdoor-list.component';
import { InteriorListComponent } from './blind-list/interior-list/interior-list.component';
import { FixturesComponent } from './fixtures/fixtures.component';
import { SharedModule } from '@shared/shared.module';
import { ViewSwitcherComponent } from './view-switcher/view-switcher.component';
import { ControlSwitcherComponent } from './control-switcher/control-switcher.component';
import { ControlPanelComponent } from './control-panel/control-panel.component';
import { ControlModelComponent } from './control-model/control-model.component';

import { DirectivesModule } from '@core/directives/directives.module';
import { SizeDisclaimerComponent } from '@features/size-disclaimer/size-disclaimer.component';

@NgModule({
	declarations: [
		MainPageComponent,
		ModelViewerComponent,
		ToolBarComponent,
		ColorChangeComponent,
		SizeChangeComponent,
		AddBlindComponent,
		BlindListComponent,
		FrameChangeComponent,
		OperationChangeComponent,
		MountingChangeComponent,
		OverviewPageComponent,
		SummaryTableComponent,
		RetailarActionComponent,
		ColorPickerComponent,
		CountryModalComponent,
		BlindTypeModalComponent,
		BlindItemDescriptionComponent,
		ShutterControlComponent,
		LatinValidatorDirective,
		InputEmailValidator,
		OutdoorListComponent,
		InteriorListComponent,
		FixturesComponent,
		ViewSwitcherComponent,
		ControlSwitcherComponent,
		ControlPanelComponent,
		ControlModelComponent,
		SizeDisclaimerComponent
	],
	exports: [
		BlindTypeModalComponent,
		CountryModalComponent
	],
	imports: [
		CommonModule,
		BrowserModule,
		MatSliderModule,
		MatDialogModule,
		MatExpansionModule,
		HttpClientModule,
		ColorPickerModule,
		ScrollbarModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		PipesModule,
		AppRoutingModule,
		SlickCarouselModule,
		TranslateModule,
		SharedModule,
		DirectivesModule
	]
})
export class FeaturesModule {
}
