import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, Subscription, throwError, timer } from 'rxjs';
import { LoaderService } from '@core/services/loader/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from '@shared/modals/error-modal/error-modal.component';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';
import { ShareService } from '@core/services/share-data/share-data.service';
import { NetworkStatus } from '@root/app.interfaces';
import { ATTEMPTS_DELAY_MS, MAX_RETRY_ATTEMPTS } from '@root/app.config';
import { ModalService } from '@core/services/modal/modal.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
	private requests: HttpRequest<any>[] = [];
	private modalStatus = true;
	
	networkStatus: NetworkStatus = {
		event: null,
		status: true
	};
	getNetworkStatus: Subscription;
	
	constructor(
		private loaderService: LoaderService,
		private shareService: ShareService,
		private modalService: ModalService,
		public dialog: MatDialog
	) {
		this.getNetworkStatus = this.shareService.getNetworkStatus.subscribe(res => {
			this.networkStatus = res;

			if (res.status) {
				this.modalStatus = true;
			}
		});
	}
	
	private removeRequest(req: HttpRequest<any>): void {
		const i = this.requests.indexOf(req);
		if (i >= 0) {
			this.requests.splice(i, 1);
		}
		this.loaderService.isLoading.next(this.requests.length > 0);
		this.getNetworkStatus.unsubscribe();
	}
	
	private isServerError(error: any): boolean {
		return error.status >= 0 && error.status < 404;
	}

	private errorModalHandler(error: Error) {
		this.modalService.openResponsiveDialog<{error: Error, network: NetworkStatus}>({
			id: 'error-modal',
			component: ErrorModalComponent,
			data: { error, network: this.networkStatus },
			disableClose: true,
			maxWidth: '80%',
			maxHeight: '100vh',
			panelClass: ''
		});
	}
	
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.requests.push(req);
		this.loaderService.isLoading.next(true);
		
		return next.handle(req).pipe(
			retryWhen(errors => errors.pipe(
				mergeMap((error, attempt) => {
					console.log('Server attempt', attempt);
					
					if (attempt < MAX_RETRY_ATTEMPTS && this.isServerError(error)) {
						return timer(ATTEMPTS_DELAY_MS);
					}
					return throwError(error);
				})
			)),
			catchError((error: Error) => {
				if (this.networkStatus.event?.type === 'offline') {
					if (this.modalStatus) {
						this.errorModalHandler(error);

						this.modalStatus = false;
					}
				} else {
					this.errorModalHandler(error);
				}

				this.loaderService.isLoading.next(false);
				
				this.removeRequest(req);
				return throwError(error);
			})
		);
	}
}
