<div class="mc-modal__content">
	<div class="mc-modal__close" (click)="closeDialog()"></div>
	<div class="mc-modal__icon"></div>
	<div class="mc-modal__title">{{ (data.embedded ? 'modals.refresh.title' : 'modals.leave.title') | translate }}</div>
	<div class="mc-modal__subtitle">{{ (data.embedded ? 'modals.refresh.subtitle' : 'modals.leave.subtitle') | translate }}</div>
	<div class="mc-modal__buttons">
		<div class="mc-modal__button"
			 (click)="closeDialog()">{{ (data.embedded ? 'modals.refresh.buttons.no' : 'modals.leave.buttons.stay') | translate }}
		</div>
		<div class="mc-modal__button mc-modal__button--yellow"
			 (click)="leave()">
			{{ (data.embedded ? 'modals.refresh.buttons.yes' : 'modals.leave.buttons.leave') | translate }}
		</div>
	</div>
</div>
