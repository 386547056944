import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { STORAGE_NAMES } from '@root/app.config';

@Component({
	selector: 'app-country-modal',
	templateUrl: './country-modal.component.html',
	styleUrls: ['./country-modal.component.scss', './country-modal.responsive.scss']
})
export class CountryModalComponent implements OnInit, OnDestroy {
	@ViewChild('countryContent') countryContent: ElementRef;
	
	getCountryPopup: Subscription;
	
	constructor(
		public shareDataService: ShareService,
		public sessionStorageService: SessionStorageService,
		public dialogRef: MatDialogRef<CountryModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {
	}
	
	ngOnInit() {
		this.getCountryPopup = this.shareDataService.getCountryPopup.subscribe(() => {
			this.spinnerHandler(false);
			
			this.dialogRef.close();
		});
	}
	
	ngOnDestroy() {
		this.getCountryPopup.unsubscribe();
	}
	
	setCountrySite(event: Event): void {
		if (!(event.currentTarget instanceof HTMLElement)) {
			return;
		}
		
		const { dataset } = event.currentTarget;
		
		this.shareDataService.setCountryLink(dataset?.code);
		this.sessionStorageService.setSession(dataset?.code, STORAGE_NAMES.zip_country_code);
		
		setTimeout(this.spinnerHandler.bind(this, true));
	}
	
	spinnerHandler(status: boolean): void {
		this.countryContent.nativeElement.classList.toggle('loading', status);
	}
}
