import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { EngineService } from '@core/services/engine/engine.service';
import { CANVAS_BACKGROUND_COLOR, SELECTORS, STORAGE_NAMES } from '@root/app.config';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import html2canvas from 'html2canvas';
import { FormBuilder, Validators } from '@angular/forms';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';

@Component({
	selector: 'app-save-work-modal',
	templateUrl: './save-work-modal.component.html',
	styleUrls: ['./save-work-modal.component.scss', './save-work-modal.responsive.scss']
})
export class SaveWorkModalComponent implements OnInit, OnDestroy {
	downloading = false;
	saving = false;
	sending = false;
	submitted: boolean;
	emailSaved: boolean;
	
	emailRegex = new RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$');
	form = this.fb.group({
		email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
	});
	
	embeddedRetailer = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_embedded_retailer);
	
	get f() {
		return this.form.controls;
	}
	
	constructor(
		private fb: FormBuilder,
		private screenShotService: ScreenshotService,
		private saveSessionBlind: SaveSessionBlindsService,
		private sessionStorageService: SessionStorageService,
		private engineService: EngineService,
		public dialogRef: MatDialogRef<SaveWorkModalComponent>
	) {
	}
	
	ngOnInit() {
		this.engineService.setGizmoControlVisible(false);
		document.querySelector(SELECTORS.overlay_container).classList.add('internal');
	}
	
	ngOnDestroy() {
		const currentMeshIsVisible = this.engineService.getCurrentRootMesh()?.isEnabled();
		this.engineService.setGizmoControlVisible(currentMeshIsVisible);
		document.querySelector(SELECTORS.overlay_container).classList.remove('internal');
	}
	
	onDownloadImage() {
		this.downloading = true;
		const renderCanvas = document.getElementById(SELECTORS.render_canvas);
		
		html2canvas(renderCanvas, { backgroundColor: CANVAS_BACKGROUND_COLOR }).then((res) => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			
			canvas.width = res.width;
			canvas.height = res.height;
			ctx.drawImage(res, 0, 0);
			
			const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
			
			let totalBrightness = 0;
			for (let i = 0; i < imageData.data.length; i += 4) {
				const brightness = 0.299 * imageData.data[i] + 0.587 * imageData.data[i + 1] + 0.114 * imageData.data[i + 2];
				totalBrightness += brightness;
			}
			const averageBrightness = totalBrightness / (imageData.data.length / 4);
			const watermarkSrc =
				averageBrightness > 128
				? '../../../../assets/images/watermark-dark-logo.svg'
				: '../../../../assets/images/watermark-light-logo.svg';
			
			const img = new Image();
			img.src = watermarkSrc;
			
			img.onload = () => {
				const patternCanvas = document.createElement('canvas');
				const patternContext = patternCanvas.getContext('2d');
				
				patternCanvas.width = img.width;
				patternCanvas.height = img.height;
				
				patternContext.drawImage(img, 0, 0);
				
				ctx.fillStyle = ctx.createPattern(patternCanvas, 'repeat');
				ctx.globalAlpha = 0.3;
				ctx.fillRect(0, 0, canvas.width, canvas.height);
				
				const image = canvas.toDataURL();
				
				const link = document.createElement('a');
				link.download = 'Ziptrak®-Design-Project.jpeg';
				link.href = image;
				link.click();
				link.remove();
				
				this.downloading = false;
			};
		});

	}
	
	onSaveWork() {
		this.saving = true;
	}
	
	async onSubmit() {
		this.sending = true;
		this.submitted = true;
		
		if (this.form.valid) {
			this.saving = true;
			this.dialogRef.disableClose = true;

			this.engineService.setIVSceneScreenShots(false).then(() => {
				this.engineService.saveModelAndBoundingBoxSettings(true, false);

				this.saveSessionBlind.putSaveBlindsPromise(this.form.value.email).then(() => {
					this.dialogRef.disableClose = false;
					this.sending = false;
					this.emailSaved = true;
				});
			});
		} else {
			this.sending = false;
		}
	}
	
	close(): void {
		this.emailSaved = false;
		this.dialogRef.close();
	}
}
