<div class="mc-add-blind__container" [class]="{'collapsed': isToolBarCollapsed}">
	<button class="mc-add-blind__button"
			[ngClass]="setBlindInfoStatus ? 'active' : ''" (click)="onAddBlindHandler()">Add blind <span></span>
	</button>
	<button class="mc-overview-button"
			id="add-blind-overview-button"
			[disabled]="overviewStatus"
			(click)="overviewLinkHandler()">See Overview
	</button>
</div>
