import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SELECTORS } from '@root/app.config';

declare var $: any;

@Component({
	selector: 'app-retailer-select-modal',
	templateUrl: './retailer-select-modal.component.html',
	styleUrls: ['./retailer-select-modal.component.scss', './retailer-select-modal.responsive.scss']
})
export class RetailerSelectModalComponent implements OnInit, OnDestroy {
	constructor(
		public dialogRef: MatDialogRef<RetailerSelectModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: boolean
	) {
	}
	
	ngOnInit() {
		$(SELECTORS.overlay_container).addClass('internal');
	}
	
	ngOnDestroy() {
		$(SELECTORS.overlay_container).removeClass('internal');
	}
	
	close(): void {
		this.dialogRef.close();
	}
	
	delete(): void {
		this.dialogRef.close(true);
	}
}
