<div *ngIf="blindItemType === 'material'" class="mc-blind-item">
	<span class="mc-blind-item-name">{{ blindItem?.name }};</span>
	<span class="mc-blind-item-color material"
		  [style.background]="blindItem?.opacity >= minimumMaterialOpacity ?
		  	'linear-gradient(315deg,' + blindItem.color +' 58.49%, ' + blindItem.color + '80 100%)' : '#ffffff'"
		  [attr.data-name]="blindItem?.color_name"></span>
</div>

<div *ngIf="blindItemType === 'size'" class="mc-blind-item">
	<span class="mc-blind-item-name" [ngClass]="{'extra-size': blindItem?.extra_size}">{{ blindItem?.width }}
		mm x {{ blindItem?.height }} mm</span>
</div>

<div *ngIf="blindItemType === 'frames'" class="mc-blind-item">
    <span class="mc-blind-item-color"
		  *ngIf="!blindItem?.frame_color?.is_custom"
		  [style.background]=" 'linear-gradient(315deg,' +
          (blindItem?.frame_color?.is_custom ? blindItem?.frame_color?.last_saved_color: blindItem?.frame_color.color) +
          ' 58.49%, ' + (blindItem?.frame_color?.is_custom ? blindItem?.frame_color?.last_saved_color : blindItem?.frame_color.color) + '80 100%)'"
		  [attr.data-name]="blindItem?.frame_color?.color_name ? blindItem?.frame_color?.color_name : blindItem?.frame_color?.name"></span>
	<span class="mc-blind-item-name" *ngIf="blindItem?.frame_color?.is_custom">{{ blindItem?.frame_color?.color_name }}
		;</span>
	<span class="mc-blind-item-name" *ngIf="blindItem?.top_style?.name && blindType !== 'interior'">
        {{ blindItem?.top_style?.name }};
    </span>
	<span class="mc-blind-item-name"
		  *ngIf="blindItem?.bottom_bar?.name && blindType === 'outdoor'">{{ blindItem?.bottom_bar?.name }}</span>
	<span class="mc-blind-item-name" *ngIf="blindItem?.optionals.length">{{ blindItem?.optionals[0]?.name }}</span>
</div>

<div *ngIf="blindItemType === 'operation'" class="mc-blind-item">
    <span class="mc-blind-item-name">
        {{ blindType === 'outdoor' ? blindItem?.long_name : blindItem?.name }}
		<ng-container
				*ngFor="let option of blindItem?.optional">; {{ option.name }}</ng-container>
    </span>
</div>

<div *ngIf="blindItemType === 'fixtures'" class="mc-blind-item">
	<span class="mc-blind-item-name">{{ blindItem?.name }}</span>
	<span class="mc-blind-item-color"
		  [style.background]="'linear-gradient(315deg,' + blindItem?.color +' 58.49%, ' + blindItem?.color + '80 100%)'"
		  [attr.data-name]="blindItem?.color_name"></span>
</div>

<div *ngIf="blindItemType === 'mounting'" class="mc-blind-item">
	<span class="mc-blind-item-name">{{ blindItem?.name }}</span>
</div>
