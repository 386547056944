<div class="mc-mounting__container">
	<div class="mc-mounting__list" *ngFor="let item of serverMountingData; let i = index">
		<div class="mc-mounting__block">
			<input type="radio" class="mc-mounting__input" name="mountingType"
				   id="{{blindId}}{{item.name | replace:' ':''}}{{random}}"
				   value="{{item.name}}"
				   [checked]="item.checked || defaultId === item.id">

			<label for="{{blindId}}{{item.name | replace:' ':''}}{{random}}"
				   data-id="{{item.id}}"
				   class="mc-mounting__label"
				   (click)="onSubmitRadioOptions($event)">
                <span class="mc-mounting__radio" tabindex="0">
                    {{ item.name }}
                </span>
			</label>

			<span class="mc-info-icon-default" (click)="$event.stopPropagation()"
				  #popover="ngbPopover" #popoverButton
				  (shown)="onPopover(popover, popoverButton)"
				  (hidden)="onPopover(popover, popoverButton)"
				  placement="left"
				  popoverClass="mc-popover mc-popover-blind-info"
				  [ngbPopover]="sessionText['mounting_pop_up_text_' + (i + 1) + (blindType === 'interior' ? '_interior' : '')]"></span>
		</div>
	</div>
</div>
