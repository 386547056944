import { Injectable } from '@angular/core';
import { BackgroundConfig } from '@root/app.interfaces';

@Injectable({
	providedIn: 'root'
})
export class IndexedDBService {

	EXISTENCE_TIME_LIMIT_IN_HOUR = 2;

	constructor() { }

	saveImage<T extends { id: string }>(data: T, storage: string): void {
		const openRequest = indexedDB.open('zip-store', 1);

		this.createDB(openRequest, storage);

		openRequest.onsuccess = () => {
			const db = openRequest.result;
			const transaction = db.transaction(storage, 'readwrite');
			const store = transaction.objectStore(storage);

			data['time'] = new Date();

			store.put(data);
		};
	}

	getImage(storage: string, id: string): Promise<BackgroundConfig | null> {
		const promise: Promise<BackgroundConfig | null> = new Promise((resolve) => {
			const openRequest = indexedDB.open('zip-store', 1);

			this.createDB(openRequest, storage);

			openRequest.onsuccess = () => {
				const store = openRequest.result.transaction(storage).objectStore(storage);
	
				const request = store.get(id);
				request.onsuccess = () => {
					const createdTime: Date = request.result?.time;

					if (createdTime) {
						const currentTime = new Date();
						const diff = currentTime.getTime() - createdTime.getTime();
						const hour = Math.floor(diff / 1000 / 60 / 60);
						
						if (hour >= this.EXISTENCE_TIME_LIMIT_IN_HOUR) {
							resolve(null);
							return;
						}
					}

					resolve(request.result);
				};
			};
		});

		return promise;
	}

	createDB(openRequest: IDBOpenDBRequest, storage: string): void {
		openRequest.onupgradeneeded = (event) => {
			const db = openRequest.result;

			if (!event.oldVersion) {
				db.createObjectStore(storage, {keyPath: 'id'});
			}
		};
	}

	deleteDB(): void {
		indexedDB.deleteDatabase('zip-store');
	}

}
