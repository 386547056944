<div class="mc-modal__content">
	<button class="mc-modal__close" (click)="close()" [disabled]="sending"></button>
	<div class="mc-modal__title" *ngIf="!emailSaved">{{ 'modals.save_work.title' | translate }}</div>
	<div class="mc-modal__title" *ngIf="emailSaved">{{ 'modals.save_work.success_title' | translate }}</div>

	<div class="mc-modal__subtitle" *ngIf="!saving">
		{{ (embeddedRetailer ? 'modals.save_work.subtitle_embedded_retailer' : 'modals.save_work.subtitle') | translate }}
	</div>

    <div class="mc-modal__subtitle" *ngIf="emailSaved">
        {{ 'modals.save_work.success' | translate }}
    </div>

	<div class="mc-modal__buttons" *ngIf="!saving">
		<div class="mc-modal__button mc-modal__button--yellow mc-modal__button--download" (click)="onDownloadImage()"
			 [ngClass]="{'loading': downloading, 'embedded-retailer': embeddedRetailer}">
			{{ 'modals.save_work.buttons.download' | translate }}
		</div>
		<div class="mc-modal__button mc-modal__button--save" (click)="onSaveWork()"
			 *ngIf="!embeddedRetailer">
			{{ 'modals.save_work.buttons.save' | translate }}
		</div>
	</div>

    <div class="mc-modal__buttons" *ngIf="emailSaved">
        <div class="mc-modal__button mc-modal__button--yellow mc-modal__button--download" (click)="onDownloadImage()"
             [ngClass]="{'loading': downloading, 'embedded-retailer': embeddedRetailer}">
            {{ 'modals.save_work.buttons.download' | translate }}
        </div>
        <div class="mc-modal__button mc-modal__button--close" (click)="close()">
            {{ 'modals.save_work.buttons.close' | translate }}
        </div>
    </div>

    <div class="mc-modal__copy" *ngIf="!saving">{{ 'modals.save_work.privacy' | translate }}</div>

    <form class="mc-modal__form" (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="saving && !emailSaved">
        <div class="mc-modal__subtitle">
            <p>{{ 'modals.save_work.email' | translate }}</p>
            <div class="mc-modal__form-section">
                <input [ngClass]="{'mc-modal__form-section-input--error': submitted && f.email.errors}"
                       [placeholder]="'modals.save_work_email.inputs.email.placeholder' | translate"
                       class="mc-modal__form-section-input"
                       formControlName="email"
                       id="email" required type="text">

                <div *ngIf="submitted && f.email.errors && f.email.errors['required']"
                     class="mc-modal__form-section-message">
                    {{ 'modals.save_work_email.inputs.email.errors.required' | translate }}
                </div>
                <div *ngIf="submitted && f.email.errors && f.email.errors['pattern']"
                     class="mc-modal__form-section-message">
                    {{ 'modals.save_work_email.inputs.email.errors.wrongFormat' | translate }}
                </div>
            </div>
        </div>

        <div class="mc-modal__buttons">
            <div class="mc-modal__button mc-modal__button--back"
                    [class]="{'disabled': sending}"
                    (click)="saving = false">
                {{ 'modals.save_work.buttons.back' | translate }}
            </div>
            <button class="mc-modal__button mc-modal__button--save"
                    type="submit"
                    [ngClass]="{'loading': sending}">
                {{ 'modals.save_work.buttons.send' | translate }}
            </button>
        </div>
        <div class="mc-modal__copy">{{ 'modals.save_work.design' | translate }}</div>
    </form>
</div>
