import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
	selector: 'app-to-top-btn',
	templateUrl: './to-top-btn.component.html',
	styleUrls: ['./to-top-btn.component.scss']
})
export class ToTopBtnComponent implements OnInit, OnDestroy {
	isActive = false;
	scrollPosition = 0;
	
	constructor() {
	}
	
	ngOnInit(): void {
		window.addEventListener('scroll', () => {
			this.scrollPosition = window.scrollY;
			this.isActive = this.scrollPosition > 150;
		});
	}
	
	ngOnDestroy(): void {
	}
	
	scrollToTop(): void {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}
}
