<div class="mc-operation__container" data-id="{{blindId}}">
	<div *ngFor="let item of serverOperationData; let i = index"
		 class="mc-operation__list"
		 data-id="{{item.id}}"
		 [ngClass]="{'checked': item.checked || defaultId === item.id}">
		<div class="mc-operation__block">
			<input type="radio" class="mc-operation__input" name="operationType"
				   id="{{blindId}}{{item.name | replace:' ':''}}{{random}}"
				   value="{{item.long_name}}"
				   [checked]="item.checked || defaultId === item.id">

			<label for="{{blindId}}{{item.name | replace:' ':''}}{{random}}"
				   data-id="{{item.id}}"
				   class="mc-operation__label"
				   (click)="onSubmitRadioOptions($event)">
                <span class="mc-operation__radio" tabindex="0">
                    {{ blindType === 'outdoor' ? item.long_name : item.name }}
                </span>
			</label>

			<span class="mc-info-icon-default" (click)="$event.stopPropagation()"
				  #popover="ngbPopover" #popoverButton
				  (shown)="onPopover(popover, popoverButton)"
				  (hidden)="onPopover(popover, popoverButton)"
				  placement="left"
				  popoverClass="mc-popover mc-popover-blind-info"
				  [ngbPopover]="sessionText['operation_pop_up_text_' + (i + 1) + (blindType === 'interior' ? '_interior' : '')]"></span>
		</div>
		<div class="mc-operation__optional">
			<div class="mc-operation__optional-title">Optional</div>
			<div *ngFor="let option of item.optional" class="mc-operation__optional-block">
				<input type="checkbox" class="mc-operation__input"
					   id="{{blindId}}{{option.name | replace:' ':''}}{{random}}"
					   name="{{option.name}}" [checked]="option.checked">
				<label class="mc-operation__label"
					   for="{{blindId}}{{option.name | replace:' ':''}}{{random}}"
					   data-id="{{option.id}}" (click)="onSubmitCheckboxOptions($event)">
					<span class="mc-operation__optional-checkbox" tabindex="0"></span>
					<span class="mc-operation__optional-label">{{ option.name }}</span>
				</label>
			</div>
		</div>
	</div>
</div>
