<div class="mc-modal__content">
    <div class="mc-modal__close" (click)="closeDialog()"></div>
    <div class="mc-modal__icon"></div>
    <div class="mc-modal__title" [innerHTML]="(removedStatus ? 'modals.link_unavailable.title_removed' : 'modals.link_unavailable.title') | translate"></div>
    <div class="mc-modal__subtitle"
         *ngIf="removedStatus">{{ 'modals.link_unavailable.subtitle_removed' | translate }}</div>
    <div class="mc-modal__subtitle">{{ 'modals.link_unavailable.subtitle' | translate }}</div>
    <div class="mc-modal__buttons">
        <div class="mc-modal__button mc-modal__button--yellow" (click)="closeDialog()">
            {{ 'modals.link_unavailable.button' | translate }}
        </div>
    </div>
</div>
