import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ShareService } from '@core/services/share-data/share-data.service';
import { Subscription } from 'rxjs';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { EngineService } from '@core/services/engine/engine.service';
import { SimpleChanges } from '@angular/core';
import {
	REVEAL_CHANNEL,
	FACE_FIX,
	REVEAL_CHANNEL_INTERIOR,
	FACE_FIX_INTERIOR,
	STORAGE_NAMES,
	VIEW_TYPES,
	BOTTOM_CHANNEL_HANDLE
} from '../../app.config';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';

declare var $: any;

@Component({
	selector: 'app-mounting-change',
	templateUrl: './mounting-change.component.html',
	styleUrls: ['./mounting-change.component.scss', './mounting-change.responsive.scss']
})
export class MountingChangeComponent implements OnInit, OnDestroy, OnChanges {
	@Input() blindId;
	@Output() mountingData = new EventEmitter();
	
	getBlindSelect: Subscription;
	serverMountingData;
	storageData = {
		mounting: {
			id: '',
			checked: false
		}
	};
	blindType = this.sessionStorageService.getSession(STORAGE_NAMES.zip_blind_type);
	sessionConfig = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config);
	sessionText = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_text);
	isDesignType: boolean =
		this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type) === VIEW_TYPES.design;
	
	selectors = {
		list: '.mc-mounting__list',
		input: '.mc-mounting__input'
	};
	defaultId;
	random = Math.floor(10000 + Math.random() * 1000);
	
	constructor(
		private sessionStorageService: SessionStorageService,
		private shareDataService: ShareService,
		private saveSessionBlind: SaveSessionBlindsService,
		private engineService: EngineService,
		private changeDetection: ChangeDetectorRef
	) {
	}
	
	ngOnInit(): void {
		this.serverMountingData = this.sessionConfig?.[this.blindType].frame.mounting;
		
		this.getStorageMounting();
		this.getBlindSelect = this.shareDataService.getBlindSelect.subscribe(this.setBlindSelectId.bind(this));
	}
	
	ngOnChanges(changes: SimpleChanges): void {
		const getCurrentBLindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id);
		
		if (changes.blindId.currentValue === getCurrentBLindId) {
			setTimeout(this.setDefaultValue.bind(this));
		}
	}
	
	ngOnDestroy(): void {
		this.getBlindSelect.unsubscribe();
	}
	
	getStorageMounting(): void {
		const mountingStorage = this.sessionStorageService.getBlindItemById(this.blindId, STORAGE_NAMES.zip_blind_data);
		if (mountingStorage) {
			for (const item of mountingStorage) {
				if (item.setup && item.setup.mounting) {
					this.storageData.mounting = this.serverMountingData.find(x => x.id === item.setup.mounting.id);
					this.storageData.mounting.checked = true;
					this.modelManagingHandler(this.storageData.mounting.id, 'storage');
				}
			}
		}
	}
	
	onSubmitRadioOptions(event): void {
		const currentOption = $(event.currentTarget);
		
		if (!currentOption.prev().is(':checked')) {
			const optionID = currentOption.attr('id');
			this.modelManagingHandler(optionID, event);
		}
	}
	
	modelManagingHandler(operationId, event): void {
		this.blindType = this.sessionStorageService.getSession(STORAGE_NAMES.zip_blind_type);
		const getCurrentData = this.serverMountingData.find(x => x.id === +operationId);
		const meshArray = this.blindType === 'outdoor' ? (+operationId === 1 ? REVEAL_CHANNEL : FACE_FIX)
			: (+operationId === 1 ? REVEAL_CHANNEL_INTERIOR : FACE_FIX_INTERIOR);
		const blindData = this.sessionStorageService.getBlindItemById(this.blindId, STORAGE_NAMES.zip_blind_data)[0];
		const bottomChannelSelected = blindData?.setup?.frames?.optionals.length;
		
		if (this.blindType === 'interior' && bottomChannelSelected) {
			const type = getCurrentData.name.toLowerCase();
			
			BOTTOM_CHANNEL_HANDLE.forEach(mesh => {
				this.engineService.setBottomChannel({
					meshes: [mesh],
					state: mesh.toLowerCase().includes(type),
					type: event
				});
			});
		}
		
		this.engineService.setMounting({
			meshes: meshArray,
			id: operationId,
			type: event.type || event,
			description: getCurrentData.description
		});
		
		if (+this.storageData.mounting.id !== +operationId) {
			this.storageData.mounting = { ...this.storageData.mounting, ...getCurrentData };
			this.saveBlindSetup();
		}

		if (this.blindType === 'interior' && +operationId === 2) {
			this.engineService.getInteriorFaceFixStopper(!bottomChannelSelected);
		}
		
		this.checkingRadio(getCurrentData);
	}
	
	setBlindSelectId(data): void {
		this.serverMountingData = this.sessionConfig[this.blindType].frame.mounting;
		
		if (data.id === this.blindId) {
			this.getStorageMounting();
		}
		
		this.changeDetection.markForCheck();
	}
	
	setDefaultValue(): void {
		const frameConfig = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config)[this.blindType].frame;
		const materialStorage = this.sessionStorageService.getBlindItemById(this.blindId, STORAGE_NAMES.zip_blind_data);
		
		if (materialStorage?.[0].setup.mounting) {
			return;
		}
		
		for (const item of frameConfig.mounting) {
			if (item.is_default) {
				this.modelManagingHandler(item.id, 'loading');
				this.defaultId = item.id;
			}
		}
	}
	
	checkingRadio(operationData): void {
		const inputId = `${ this.blindId }${ operationData.name.split(' ').join('') }${ this.random }`;
		
		$(`#${ inputId }`).prop('checked', true);
	}
	
	onPopover(popover: NgbPopover, button: HTMLElement): void {
		$(button).toggleClass('active', popover.isOpen());
	}
	
	saveBlindSetup(): void {
		this.mountingData.emit(this.storageData);
		this.sessionStorageService.setBlindSetupDataById(this.blindId, this.storageData, STORAGE_NAMES.zip_blind_data);
		this.saveSessionBlind.PutStorageDataToServer();
	}
	
}
