import { Component, OnDestroy, OnInit } from '@angular/core';
import { SELECTORS } from '@root/app.config';
import { MatDialogRef } from '@angular/material/dialog';

declare var $: any;

@Component({
	selector: 'app-disabled-link-modal',
	templateUrl: './disabled-link-modal.component.html',
	styleUrls: ['./disabled-link-modal.component.scss', './disabled-link-modal.responsive.scss']
})
export class DisabledLinkModalComponent implements OnInit, OnDestroy {

	constructor(
		public dialogRef: MatDialogRef<DisabledLinkModalComponent>,
	) {}

	ngOnInit() {
		$(SELECTORS.overlay_container).addClass('internal');
	}

	ngOnDestroy() {
		$(SELECTORS.overlay_container).removeClass('internal');
	}

	closeDialog(): void {
		this.dialogRef.close();
	}
}
